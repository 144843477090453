.dimaco__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.dimaco__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dimaco___navbar-links_logo{
    margin-right: 2rem;
}

.dimaco__navbar-links_logo img{
    width: 62px;
    height: 16px;
}

.dimaco__navbar-links_container{
    display: flex;
    flex-direction: row;

}

.dimaco__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dimaco__navbar-links_container p
,.dimaco__navbar-sign p
,.dimaco__navbar-menu_container p {
    color:#FFF;
    font-family: var(--font-family);
    font-weight: 500;;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin : 0 1rem;
    cursor: pointer;
}

.dimaco__navbar-sign button
,.dimaco__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color:#FFF;
    background:#FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border:none;
    outline: none;
    border-radius: 5px;
}

.dimaco__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.dimaco__navbar-menu svg{
    cursor: pointer;
}

.dimaco__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top:40px;
    right:0;
    margin-top:1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.dimaco__navbar-menu_container p{
    margin: 1rem 0;

}

.dimaco__navbar-menu_container-links-sign{
    display: none;
}

@media screen and (max-width:1050px){
    .dimaco__navbar-links_container{
        display: none;
    }

    .dimaco__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width:700px){
    .dimaco__navbar-links_container{
        padding: 2rem 4rem;
    }

}

@media screen and (max-width:550px){
    .dimaco__navbar-links_container{
        padding: 2rem;
    }
    .dimaco__navbar-sign{
        display: none;
    }
    .dimaco__navbar-menu_container{
        top:20px;
    }
    .dimaco__navbar-menu_container-links-sign{
        display: block;
    }
}